<!--
 * @Descripttion:
 * @version:
 * @Author: weixin
 * @Date: 2019-12-25 20:07:18
 * @LastEditors: 霍铁樱
 * @LastEditTime: 2020-12-28 15:59:26
 -->

 <template>
   <div class="mainbody">
    <md-tabs>
        <div class="type-in">
          <md-field>
            <div class="extrafold" @click="showjb = !showjb">
              <p>基本信息</p>
              <img
                src="@/assets/abd/image/extraright1.png"
                style="width:6vw;height:4vw;padding-left:2vw"
                v-if="!showjb"
              />
              <img
                src="@/assets/abd/image/extratobottom.png"
                style="width:5vw;height:6vw;"
                v-if="showjb"
              />
            </div>
            <div class="jbbr" v-show="showjb">
              <md-input-item
                title="保单号："
                :solid="false"
                v-model="riskconList.policyno"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="业务状态："
                :solid="false"
                v-model="riskconList.stateInfo.label"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="理赔项目："
                :solid="false"
                v-model="riskconList.xmname"
                align="right"
                readonly
              ></md-input-item>
                <md-textarea-item
                  title="项目详情："
                  :autosize="true"
                  v-model="riskconList.xndesc"
                  :max-height="300"
                  readonly
                />
              <md-input-item
                title="申请时间："
                :solid="false"
                v-model="riskconList.sqdate"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="受理时间："
                :solid="false"
                v-model="riskconList.sldate"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="申请人姓名："
                :solid="false"
                v-model="riskconList.squsername"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="申请人电话："
                :solid="false"
                v-model="riskconList.squsertel"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="保全金额(元)："
                :solid="false"
                v-model="riskconList.mount"
                align="right"
                readonly
              ></md-input-item>
              <md-textarea-item
                  title="处理意见："
                  :autosize="true"
                  v-model="riskconList.fsreason"
                  :max-height="300"
                  readonly
                />
            </div>
          </md-field>
        </div>
      </md-tabs>
    </div>
 </template>

<script>
import { Toast, ImageReader } from 'mand-mobile'
import { bqShow } from '@/api/hx/preservation/index'
import { getSelectList } from '@/api/agent/agent'
export default {
  data () {
    return {
      /* 保单详情 */
      showjb: true,
      policyList: {},
      riskconList: { ywcodeInfo: {}, stateInfo: {} },
      pcnum: '',
      appfname: '',
      ywtype: ''
    }
  },
  created () {
    this.policydetail()
  },
  methods: {
    async policydetail () {
      let data = {
        pcnum: this.$route.query.pcnum,
        ywtype: this.$route.query.ywtype
      }
      await bqShow(data).then(res => {
        let policyList = res.data.data
        this.riskconList = policyList.pos
        this.riskconList.ywcodeInfo && this.riskconList.ywcodeInfo.value && this.riskconList.ywcodeInfo.label ? '' : this.riskconList.ywcodeInfo = { label: '', value: '' }
        this.riskconList.stateInfo && this.riskconList.stateInfo.value && this.riskconList.stateInfo.label ? '' : this.riskconList.stateInfo = { label: '', value: '' }
      })
    }
  }
}
</script>

 <style lang="stylus" scoped>
    .mainbody{
      background-color  #EEEEEE
    }
  /deep/ .require {
    .md-field-item-title::after {
      content: '*';
      color: red;
    }
  }
  /deep/ .tjmes {
    .md-field-item-title {
      padding-left: 0;
      font-weight: bold;
    }
  }
  /deep/ .additionalrisks{
    .md-field-item-right {
      img{
        width 4vw
        height 4vw
      }
    }
  }
//   <!-- 附加险折叠 -->
  .extrafold{
    display flex
    height 8vh
    // border-top  10px solid #F8F8FA
    border-bottom  10px solid #F8F8FA
    justify-content space-between
    align-items center
    p{
      font-size 40px
      font-family 'PingFang-SC'
      font-weight bold
      color black
    }
      img{
        width 3vw
        height 4vw
      }
    }
  /deep/.md-textarea-item__textarea{
    text-align right;
  }
 </style>
